import { DatePicker, Form, Input } from 'antd';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { API_DATE_FS } from './shared';


export class NdwsFormItems extends PureComponent {
  static slug = 'ndws';

  constructor(props) {
    super(props);
    this.state = { };
  }

  static getSubmissionData(checkData, constituent) {
    return {
      identifier: checkData.document_number,
      issued_by: checkData.issued_by,
      ...checkData.expiry && { expiry: checkData.expiry.format(API_DATE_FS)},
      fullName: constituent.get('fullName'),
    };
  }

  disablePastDate = current => {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  render() {
    const { form, disabled, rules } = this.props;
    const { getFieldDecorator } = form;
    const { slug } = this.constructor;

    const ident = {
      label: 'Certificate Number',
      slug: `${slug}.document_number`,
    };
    const issuedBy = {
      label: 'Issued By',
      slug: `${slug}.issued_by`,
    };
    const expiry = {
      label: 'Expiry',
      slug: `${slug}.expiry`,
    };

    return (<>
      <Form.Item label={ ident.label }>
        { getFieldDecorator(ident.slug, {
          rules: [
            {
              required: true,
              whitespace: true,
              message: `${ident.label} is required`,
            },
            ...(rules || []),
          ],
        })(
          <Input
            disabled={ disabled }
          />,
        )
        }
      </Form.Item>
      <Form.Item label={ expiry.label }>
      {getFieldDecorator(expiry.slug, {
          rules: [...(rules || [])],
        })(
          <DatePicker
            disabled={disabled}
            disabledDate={this.disablePastDate}
          />,
        )}
      </Form.Item>
      <Form.Item label={ issuedBy.label }>
        { getFieldDecorator(issuedBy.slug, {
          initialValue: 'Quality and Safeguarding Commission',
          rules: [
            {
              required: true,
              whitespace: true,
              message: `${issuedBy.label} is required`,
            },
            ...(rules || []),
          ],
        })(
          <Input
            disabled={ disabled }
          />,
        )
        }
      </Form.Item>
    </>);
  }
}
