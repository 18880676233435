import moment from 'moment';
import { Form, Input, DatePicker } from 'antd';
import React, { PureComponent } from 'react';
import { API_DATE_FS, GEN_ACC_IDENTIFIER } from './shared';

function disablePastDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf('day');
}

export class GenAccFormItems extends PureComponent {
  static slug = 'genaccIntl';

  static placeholder = '';

  static identLabel = 'Card number'; // More id names and labels have to be added

  static getSubmissionData(checkData, constituent) {
    return {
      identifier: checkData && checkData.identifier,
      date: checkData && checkData.date.format(API_DATE_FS),
      date_type: checkData && checkData.date_type,
      issued_by: checkData && checkData.issued_by,
      origin: 'doc',
    };
  }

  disableAttainmentDate = current => {
    return current && current > moment().endOf('day');
  }

  static regNumberGetValueFromEvent(evt) {
    return evt.target.value.replace(GEN_ACC_IDENTIFIER, '');
  }

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const {
      form, disabled, rules, genAccredSlug, accredDefinition,
    } = this.props;
    const { getFieldDecorator } = form;
    GenAccFormItems.slug = genAccredSlug;
    const {
      slug,
      regNumberGetValueFromEvent,

    } = this.constructor;

    return (<div>
      <Form.Item label="Identifier">
        {getFieldDecorator(`${slug}.identifier`, {
          getValueFromEvent: regNumberGetValueFromEvent,
          rules: [
            {
              required: accredDefinition.identifierMandatory,
              whitespace: true,
              message: 'identifier is required',
            },
            ...(rules || []),
          ],
        })(
          <Input
            placeholder='Identifier'
            disabled={disabled}
            size="default"
          />,
        )}
      </Form.Item>
      <Form.Item label="date type" style={{ display: 'none' }}>

        {getFieldDecorator(`${slug}.date_type`, {
          setFieldsValue: accredDefinition.dateType,
          initialValue: accredDefinition.dateType,
        })(<Input type="hidden" />)}
      </Form.Item>
      <Form.Item
        label={accredDefinition.dateType.charAt(0).toUpperCase() + accredDefinition.dateType.slice(1)}
      >
        {(getFieldDecorator(`${slug}.date`, {
          rules: [
            {
              required: true,
              message: `${accredDefinition.dateType} is required`,
            },
            ...(rules || []),
          ],
        }))(
          <DatePicker
            format="YYYY-MM-DD"
            disabledDate={accredDefinition.dateType === 'attainment' ? this.disableAttainmentDate : disablePastDate}
          />,
        )}
      </Form.Item>
      <Form.Item label="Issued By">
        {getFieldDecorator(`${slug}.issued_by`, {
          initialValue: accredDefinition.issuedBy,
          rules: [
            {
              required: true,
              whitespace: true,
              message: 'issued by is required',
            },
          ],
        })(
          <Input
            placeholder='Organization name'
            disabled={disabled}
            size="default"
          />,
        )}
      </Form.Item>

    </div>);
  }
}
